import { ShareBtn } from "../style/mainStyle";
import React, { useEffect } from "react";
import { CloseButton, Modalbackground, ShareModal } from "../style/endingStyle";
import ClipboardJS from "clipboard";

const Sharebtn = ({ isMobile }: { isMobile: boolean }) => {
  useEffect(() => {
    const clipboard = new ClipboardJS(".btn");
    // 복사 성공 시 실행될 콜백
    clipboard.on("success", function (e) {
      alert("멍bti 주소가 복사되었습니다.");
      e.clearSelection();
    });

    // 복사 실패 시 실행될 콜백
    clipboard.on("error", function (e) {
      alert("복사 실패!");
    });

    return () => {
      clipboard.destroy();
    };
  }, []);
  const mungbtiUrl = "https://www.neekoni.com";
  const mobileShare = () => {
    window.navigator.share({
      title: "neeko의 멍bti 테스트",
      text: "당신의 반려견 mbti를 알아보세요!",
      url: "https://www.neekoni.com",
    });
  };
  const [isopenModal, setIsOpenModal] = React.useState(false);

  return (
    <>
      {isopenModal && (
        <Modalbackground>
          <ShareModal>
            <CloseButton onClick={() => setIsOpenModal((prev) => !isopenModal)}>
              x
            </CloseButton>
            <div>
              <input id="url" value={mungbtiUrl} readOnly></input>
              <button className="btn" data-clipboard-target="#url">
                복사하기
              </button>
            </div>
          </ShareModal>
        </Modalbackground>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ShareBtn onClick={() => setIsOpenModal((prev) => !prev)} key="share">
          <p>테스트 공유하기</p>
        </ShareBtn>
      </div>
    </>
  );
};

export default Sharebtn;
