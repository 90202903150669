import styled from "styled-components";

export const MainMobileDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: scroll;
`;

export const MainDesktopDiv = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  width: 500px;
  overflow: scroll;
  background: aliceblue;
  padding: 1rem;
`;
export const Progressbar = styled.div`
  background: white;
  height: 10px;
  width: 90%;
  border-radius: 15px;
  & > div {
    background-color: #ffc800;
    height: 100%;
  }
`;
export const IllustantionDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 45vh;
  & > img {
    width: 45%;
    border: 5px solid #87b467;
    border-radius: 30px;
  }
`;

export const MainButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px auto;
  gap: 1rem;
  flex-direction: column;
  /* margin: 5%; */
  & > button {
    color: black;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 30px;
    border: none;
    background: #87b467;
    height: fit-content;
    box-shadow: 0px 2px 5px gray;
    &:hover {
      background: #61824a;
    }
  }
`;

export const ShareListDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 8%;
`;

export const ShareBtn = styled.button`
  width: fit-content;
  border: none;
  background-color: #ffc800;
  border: 1px solid white;
  font-size: 1.2rem;
  border-radius: 10px;
  padding: 0.5rem;
  & > p {
    margin: 0;
    color: black;
  }
  &:hover {
    background-color: #ffffff;
  }
`;
export const ImageBox = styled.img`
  width: 2.5rem;
  height: 2.5rem;
`;
export const EndingSubtitle = styled.div``;
export const StartPage = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const TitleDiv = styled.div`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding: 5px;
`;
