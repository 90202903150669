import dogAnimation from "../asset/lottie/dogAnimation.json";
import React, { useEffect } from "react";
import { questionSet } from "../content/QuestionSet";
import {
  AnswerDiv,
  BeforeHeader,
  HomeBtn,
  QuestionDiv,
  QuestionTitle,
} from "../style/questionStyle";
import { MdNavigateBefore } from "react-icons/md";
import Lottie from "lottie-react";

function QuestionPage({
  countPage,
  setCountPage,
  choose,
  setChoose,
}: {
  countPage: number;
  setCountPage: (count: number) => void;
  choose: String[];
  setChoose: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const [question, setQuestion] = React.useState(questionSet[countPage - 1]);
  const [className, setClassName] = React.useState("");

  const backCountPage = () => {
    if (countPage === 0) {
      return;
    } else {
      setCountPage(countPage - 1);
    }
  };
  useEffect(() => {
    if (countPage <= questionSet.length && countPage !== 0) {
      setQuestion(questionSet[countPage - 1]);

      setClassName("slide-in");
      setTimeout(() => {
        setClassName("");
      }, 500);
    }
  }, [countPage]);

  return (
    <div className="slide-in">
      <BeforeHeader>
        <button onClick={() => backCountPage()}>
          <MdNavigateBefore
            size={30}
            color="black"
            style={{ margin: 0, padding: 0 }}
          />
        </button>
      </BeforeHeader>
      <div style={{ margin: "1rem" }}>
        <Lottie animationData={dogAnimation}></Lottie>
        {countPage !== 0 && (
          <Question
            question={question}
            className={className}
            countPage={countPage}
            setCountPage={setCountPage}
            choose={choose}
            setChoose={setChoose}
          />
        )}
        <HomeBtn onClick={() => setCountPage(0)}>다시 하기</HomeBtn>
      </div>
    </div>
  );
}

interface QuestionProps {
  question: {
    titleQuestion: string;
    questionList: any[];
  };
  className: string;
  countPage: number;
  setCountPage: (count: number) => void;
  choose: String[];
  setChoose: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Question = ({
  question,
  className,
  countPage,
  setCountPage,
  choose,
  setChoose,
}: QuestionProps) => {
  const computeMbti = (type: string) => {
    setCountPage(countPage + 1);
    setChoose((prev) => [...prev, type]);
  };

  return (
    <QuestionDiv className={className}>
      <QuestionTitle>{question.titleQuestion}</QuestionTitle>
      <AnswerDiv>
        {question.questionList.map((question, index) => {
          return (
            <button
              tabIndex={index}
              key={index}
              onClick={() => computeMbti(question.type)}
            >
              {question.text}
            </button>
          );
        })}
      </AnswerDiv>
    </QuestionDiv>
  );
};
export default QuestionPage;
